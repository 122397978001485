.container {
  position: relative;
  margin-bottom: 24px;
  border-radius: var(--radius-medium);
  background: var(--color-bg-shade);
  width: 100%;
  height: 50px;

  @media (width >= 650px) {
    margin-bottom: 0;
  }
}

.dropdown {
  display: block;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-10);
  border-radius: var(--radius-medium);
  background-color: var(--color-surface-solid-light);
  width: 100%;
  max-height: 50px;
  overflow: hidden;
}

/* left part of arrow */
.dropdown::before {
  position: absolute;
  top: 26px;
  right: 22px;
  transform: rotate(45deg);
  transition: all 0.2s ease;
  border-radius: 30% 0 0 30%;
  background-color: var(--color-primary);
  width: 10px;
  height: 2px;
  content: "";
}

html[dir="rtl"] .dropdown::before {
  right: unset;
  left: 16px;
}

/* right part of arrow */
.dropdown::after {
  position: absolute;
  top: 26px;
  right: 16px;
  transform: rotate(-45deg);
  transition: all 0.2s ease;
  border-radius: 0 30% 30% 0;
  background-color: var(--color-primary);
  width: 10px;
  height: 2px;
  content: "";
}

html[dir="rtl"] .dropdown::after {
  right: unset;
  left: 22px;
}

.dropdown--expanded {
  max-height: 280px;
  overflow-y: scroll;
}

.dropdown--expanded::before {
  transform: rotate(-45deg);
}

.dropdown--expanded::after {
  transform: rotate(45deg);
}

.label {
  position: relative;
  cursor: pointer;
  padding: 16px 16px 16px 60px;
  height: 50px;
  color: var(--color-text-subtle);
  font-size: var(--font-size-18px);
  line-height: 0.9;
}

html[dir="rtl"] .label {
  padding: 16px 60px 16px 16px;
}

.label::before {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M24 0v24H0V0'/%3E%3Cpath d='M20.52 18.6l-2.28-2.28a4 4 0 0 0 .76-2.91 4 4 0 1 0-1.8 3.95l2.3 2.3a.51.51 0 0 0 .71 0l.35-.35a.51.51 0 0 0-.04-.71zM12.46 14a2.54 2.54 0 1 1 5.08 0 2.54 2.54 0 0 1-5.08 0z' fill='%23DB0A40' fill-rule='nonzero'/%3E%3Cpath d='M10.35 17.5l-.35-.36a.52.52 0 0 0-.68 0l-.82.69V15a.5.5 0 0 0-.5-.5H5.5v-8h11V8a.5.5 0 0 0 .5.5h.5A.5.5 0 0 0 18 8V5.5a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5H7v4.36a.3.3 0 0 0 .49.23l2.83-2.35a.51.51 0 0 0 .03-.74z' fill='%23DB0A40'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  width: 24px;
  height: 24px;
  content: "";
}

html[dir="rtl"] .label::before {
  right: 16px;
  left: unset;
}

.label--no-logo {
  padding: 16px;
  color: var(--color-text-subtle); /* not sure if this should be darker */
}

.label--no-logo::before {
  display: none;
}

.option {
  display: flex;
  cursor: pointer;
  padding: 16px;
  height: 50px;
  color: var(--color-text);
  font-weight: 700;
  font-size: var(--font-size-18px);
  user-select: none;
  text-decoration: none;
}

.option:hover,
.option:focus {
  outline: none;
  color: var(--color-primary);
}
