.container {
  position: relative;
  margin-inline: auto;
  border-radius: var(--radius-large);
  background-color: var(--color-bg-shade);
  padding: 36px 24px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  text-align: center;

  @media (width >= 1000px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-surface-solid-light-lighter);
    padding-top: 64px;
    padding-bottom: 68px;
    padding-inline-start: 38px; /* RTL support */
    padding-inline-end: 60px; /* RTL support */
    min-height: 561px;
    overflow: initial;
    text-align: initial;
  }
}

.container--single {
  justify-content: center;
  padding: 0;

  @media (width >= 1000px) {
    justify-content: center;
    padding: 24px;
  }
}

.container--white {
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  max-width: 510px;
}

.content {
  margin: 0 0 40px;
}

@media (width >= 650px) {
  .content--white-background::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: var(--radius-large);
    background-color: var(--color-surface-solid-light-lighter);
    width: 510px;
    height: 620px;
    content: "";
  }
}

.inner {
  flex-grow: 1;
}

.kicker {
  margin-bottom: 36px;

  @media (width >= 650px) {
    z-index: 1;
    margin-bottom: 44px;
  }
}

.title {
  margin: auto;
  margin-bottom: 30px;
  max-width: 250px;
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-24px);
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    position: relative;
    z-index: 1;
    margin-bottom: 45px;
    max-width: 320px;
    font-size: var(--font-size-36px);
    line-height: 1.33;
  }

  @media (width >= 1000px) {
    margin: 0;
    margin-bottom: 45px;
  }

  @media (width >= 1440px) {
    max-width: 460px;
    font-size: var(--font-size-36px);
    line-height: 1.33;
  }
}

.title--small {
  max-width: 211px;
  font-size: var(--font-size-18px);
  line-height: 1.5;

  @media (width >= 650px) {
    max-width: 280px;
    font-size: var(--font-size-24px);
  }
}

.description {
  margin: auto;
  margin-bottom: 20px;
  max-width: 283px;
  color: var(--color-text-subtle);
  font-size: var(--font-size-18px);
  line-height: 1.33;

  @media (width >= 650px) {
    position: relative;
    z-index: 1;
    max-width: 390px;
  }

  @media (width >= 1000px) {
    margin: 0;
    margin-bottom: 36px;
    max-width: 347px;
  }

  @media (width >= 1440px) {
    font-size: var(--font-size-18px);
    line-height: 1.4;
  }
}

.description--small {
  margin-bottom: 28px;
  font-size: var(--font-size-16px);
  line-height: 1.5;
}

.button-container {
  display: flex;
  column-gap: 20px;
  row-gap: 15px;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (width >= 1000px) {
    flex-direction: row;
  }
}

.button-button {
  --cosmos-button-min-width: var(--button-min-width);
}

.link {
  --cosmos-button-color: var(--color-primary);
  --cosmos-button-color-hover: var(--color-primary-darker);
  --cosmos-button-border-color-hover: color-mix(
    in srgb,
    var(--color-primary-darker),
    transparent 80%
  );
}

.link--with-arrow {
  position: relative;
  z-index: 1;
  margin-block: 30px;
  font-size: var(--font-size-18px);

  @media (width >= 1000px) {
    margin-top: 37px;
  }
}

html[dir="rtl"] .link-icon-arrow {
  transform: scaleX(-1); /* Icon flipped for RTL */
}

.artwork {
  width: 100%;
  height: 100%;
}

.artwork-image {
  display: block;
  border-radius: var(--radius-large);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width >= 650px) {
  .rail-items {
    margin-bottom: 36px;
  }
}

.embla-container {
  display: flex;
  column-gap: 10px;
}

.rail-item {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: var(--radius-medium);
  background-image: var(--support-module-rail-item-background-image);
  background-size: cover;
  padding: 0 20px;
  width: 328px;
  height: 192px;
  text-decoration: none;
}

.rail-item::after {
  position: absolute;
  inset: 0;
  border-radius: var(--radius-medium);
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0)
  );
  content: "";
}

.rail-item-kicker {
  opacity: 0.8;
  z-index: 1;
  margin-bottom: 6px;
  color: var(--color-bg-shade);
  font-weight: 700;
  font-size: var(--font-size-12px);
  line-height: 1;
  text-transform: uppercase;
}

.rail-item-title {
  z-index: 2;
  margin-bottom: 20px;
  color: var(--color-text-light);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-20px);
  line-height: 1;
  font-family: var(--font-family-heading);
}

.search-field {
  position: relative;
  margin: auto;
  max-width: 374px;

  @media (width >= 1000px) {
    width: 100%;
    max-width: none;
  }
}

.search-field-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  inset-inline-start: 16px; /* RTL support */
  width: 24px;
  height: 24px;
}

.search-field-icon {
  position: absolute;
  margin: auto;
  inset: 0;
  width: 22px;
  height: 22px;
  color: var(--color-primary);
}

/* TODO: why does the input change colour? */
.search-field-input {
  -webkit-appearance: none;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-surface-glass-dark-20);
  border-radius: var(--radius-medium);
  background-color: var(--color-surface-solid-light-lighter);
  padding-inline-start: 60px; /* RTL support */
  padding-inline-end: 24px; /* RTL support */
  width: 100%;
  max-width: 374px;
  height: 50px;
  color: var(--color-text);
  font-size: var(--font-size-18px);
  line-height: 2;

  @media (width >= 650px) {
    max-width: none;
  }

  @media (width >= 1000px) {
    background-color: var(--color-surface-solid-light);
  }
}

.search-field-input::placeholder {
  color: var(--color-text-subtle);
}

.bot-animation-container {
  display: flex;
  align-items: center;

  @media (max-width: 999px) {
    flex-direction: column;
  }
}

.bot-animation {
  flex-shrink: 0;
  margin-bottom: 15px;
  width: 120px;

  @media (width >= 1000px) {
    margin-right: 30px;
    margin-bottom: 0;
  }
}

.assistant-button {
  --cosmos-button-min-width: var(--button-min-width);

  @media (width >= 1000px) {
    margin-top: 20px; /* visually center with robot waving animation */
  }
}

html[dir="rtl"] .assistant-button-icon {
  transform: scaleX(-1); /* Speech-bubble icon flipped for RTL */
}
